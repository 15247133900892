
.header {
    width: 100%;
    font-family: 'Anton', sans-serif;
    /* font-family: 'Oswald', sans-serif; */
    color: rgb(4, 195, 4);
    margin: 0;

    /* padding-left: 30px; */
}


.thumbs {
    color: orange;
     /* font-family: 'Indie Flower', cursive;  */
    /* font-family: 'Montserrat', sans-serif; */
}
.font {
    /* font-size: 3em;  */
    color: rgb(95, 177, 47);
    padding: 3px;
}
.h-text {
    line-height: 1.6;
    font-family: 'Montserrat', sans-serif;
    color: rgb(170, 195, 216);
}
span {
    color: orange;
}

@media (min-width: 600px) {
    .header {
        font-size: 5.5em;
        padding-top: 30px;
     
     } 
    main {
        width: 70%;
        margin: auto;
      }
    .h-text {
        width: 70%;
        margin-left: 30%;
    }
    }