.small-img-container {
    /* max-width: 120px;
    max-height: 100px; */
    max-width: 40%;
    padding: 5px;
   
}

.home-list {
    display: flex;
    justify-content: flex-start;
    margin:auto;
    margin: auto;
    background-color: whitesmoke;
    margin-bottom: 30px;
    width: 95%;
    background-color:rgb(230, 251, 245) ;
    
}
.li {
    margin:auto;
    width: 95%;
    text-decoration: none;
    list-style: none;
}
.text {
    margin-left: 3%;

}
.thumb {
    margin-left: 30%;
    padding: 10px
}
@media(min-width: 800px) {
    
    .home-list {
        width: 85%;
        display: flex;
        margin:auto;
        margin-bottom: 30px;
     
    }
    .text {
        
        margin-left: 3%;
        padding: 20px;
    }
    .small-img-container {
        /* max-width: 120px;
        max-height: 100px; */
        max-width: 20%;
        padding: 20px;
       
    }
    .li {
        margin:auto;
        width: 80%;
    }
}