.auth{
    background-position: right bottom, left top; 
    background-size: cover; 
    background-size: 100%;
    /* background-repeat: no-repeat; */
     background-image: url(https://green-images.s3.amazonaws.com/Image+URL.jpg); 
     padding-bottom: 300px;
    padding-top: 30px;
   
}

#auth {
   background-color:rgb(253, 235, 212) ;
   padding-left: 30px;
   display: flex;
   max-height: 100px;
}
.auth-form {
    padding-left: 30px;
    padding: 40px;
   
}
.form__field3 {
    width: 30%;
    margin: 5px;
    background-color:rgb(230, 251, 245) ;
}