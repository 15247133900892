.small-form {
    /* background-color: cornsilk; */
    color: rgb(43, 90, 43);
    width: 85%;
    margin: auto;
    /* background-size: cover; 
    background-size: 100%;
    background-image: url('https://green-images.s3.amazonaws.com/AdobeStock_206479411.jpeg'); */

}

.form__field {
    font-family: inherit;
    border: 0;
    border-bottom: 2px solid black;
    outline: 0;
    font-size: 1rem;
    color: grey;
    background: white;  
    padding-left: 5px;
    border-radius: 5px;
    height: 30px;
    margin: auto;
   
  }
  placeholder {
      color: transparent; 
      padding-left: 5px;
    }




  @media (min-width: 600px) {
   
    .small-form {
        width: 85%;
        margin: auto;
        padding-bottom: 5%;
    }
    .form {
        margin: auto;
        margin-left: 50%;
        /* padding: 30px;    */
        padding-top: 20px;
    }
    
    .form__field {
        width: 40%; 
        margin-left: 3%;
    }
    .button-container {
        margin: auto;
        vertical-align: bottom;
        margin-left: 50%;
    }
}  


   