.bar {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 250px;
    /* background-color: teal; */
}

.bar2 {
    

}

.yelp {
    margin: auto;
    padding: 20px;
    background-color: yellowgreen;
    color: rgb(49, 70, 49);
    font-weight: 900;
}

@media (min-width: 600px) {
    .bar {
        position: fixed;
        top: 50px;
        right: 0;
        width: 250px;
        /* background-color: teal; */
    }
    .bar2 {
        position: absolute;
        top: 250px;
        right: 0;
        width: 150px;
        background-color: teal;
        padding: 30px;
    
    }
}