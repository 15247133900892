.list-item {
    background-color: whitesmoke;
    background-color:rgb(230, 251, 245) ;
    width:90%;
    margin: auto;
    margin-bottom: 30px;
}

.img-container {
    max-width: 70%;
    padding: 15px;
    margin: auto;
}
img {
    max-width: 100%;
}

p {
    font-family: 'Montserrat', sans-serif;
}
.rating-box {
    display: flex;
}

.starsImg {
    padding-left: 10px;
    padding-bottom: 20px; 
    
}
.button-container2 {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 3%;
} 
.text-area {
    margin: 7%;
}

@media(min-width: 800px) {
    .list-item {
        display:flex;
        padding-bottom: 40px;
        padding: 30px;
        width: 80%;
        margin: auto;
        margin-bottom: 30px;
    }
    .img-container {
        /* flex:1; */
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 50%;
        padding: 40px;
        
    
    }
    .item-text {
        flex: 1.5;
        justify-content: space-between;
        margin-right: 5%;
        margin-left: 5%;
        list-style: none;
        /* border-bottom: solid 1px rgb(49, 70, 49);  */
    }
   
   
}