
.big-container {
    padding-bottom: 5%;
    color: rgb(49, 70, 49);
}
.mid-container {
    padding: 20px;
}

.form2 {
    display: flex; 
}
.section2 {
    display: flex;
}
.section3 {
    display: flex;
    padding-left: 5px;
}
.smaller-header {
    font-family: 'Anton', sans-serif;
    background-color: rgb(207, 236, 236);
    font-size: 20px;
    color: rgb(19, 87, 19);
    margin: 0;
    padding: 20px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    display: flex;
    justify-content: space-between;
}

@media (min-width: 800px) {
    .mid-container {
        padding: 35px;
        width: 70%;
        margin-left: 30%;
    }
    .form2 {
        display: block; 
    }
    
    .section2 {
        padding-left: 15px;
    }
    .section3 {
        display: flex;
        padding-left: 35px;
    }
    .section {
        padding-left: 15px;
        margin: auto;
    }
   
    
    .rev-list {
        /* display:flex; 
        flex-wrap: wrap;
        justify-content: space-evenly; 
        align-items: center;
        justify-content: center;
        flex-flow: row wrap;
        align-content: center; */
    } 

}  
