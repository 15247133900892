* {
    box-sizing: border-box;
    /* color: rgb(49, 70, 49); */
}
header {
    width: 100%;
    padding: 30px;
    background-color: rgb(39, 17, 8);
    background-color: rgb(34, 27, 11);
    /* background-color: whitesmoke;  */
    font-family: 'Anton', sans-serif;
    font-family: 'Oswald', sans-serif;
    margin: 0; 
}
body {
    background-color: rgb(50, 75, 3);
    background-position: right bottom, left top; 
    background-size: cover; 
    /* background-size: 100%; */
    /* background-repeat: no-repeat; */
    /* background-image: url(https://green-images.s3.amazonaws.com/Image+URL.jpg) ; */
    color: rgb(19, 87, 19);
}

main {
    
    width: 100%;
    background-color: rgba(204, 255, 238, 0.5); 
    /* background-color: rgba(188, 224, 191, 0.5);  */
    padding-bottom: 0px; 
    font-family: 'Dosis', sans-serif;
    font-family: 'Anton', sans-serif;
    font-family: 'Indie Flower', cursive;
    font-family: 'Montserrat', sans-serif;
    font-family: 'Oswald', sans-serif;
    /* background-image: url(https://green-images.s3.amazonaws.com/AdobeStock_310919150.jpeg); */
    margin-bottom: 0;
}

a {
    /* color: rgb(179, 209, 108); */
    color: rgb(0, 128, 0);
    text-decoration: none;
    margin: 5px;
}

button {
   
    background-color: rgb(39, 134, 10);
    border: 0px;
    padding: 10px;
    padding-left : 25px;
    padding-right: 25px;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    color: white;
    margin: 10px;
}
.disabled {
    background-color: rgb(85, 98, 87);
}
input, h2, ul {
    margin: 0;
    padding: 0;
}

img {
    max-width: 30%;
    margin: auto;
    display: block;
}
ul, p , h3, h2 { 
    margin: 0;
    padding: 0;
    
    /* display: inline; */
  }

.error {
min-height: 22px;
color: rgb(172, 25, 6);
font-size: larger;

} 
.disabled {
    background-color: grey;
}

@media (min-width: 600px) {
    header {
      width: 80%;
      margin: auto;
     
      /* padding : 50px; */
      /* padding-bottom: 70px; */
     } 
    main {
        width: 70%;
        margin:auto,
    }
    button {
        margin: 20px;
    }
    }